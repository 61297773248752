import { useForm } from "react-hook-form";
import {
  Campaign,
  CampaignClient,
  GroupClient,
  InvestmentStage,
} from "../../utilities/backend/client";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PhotoCamera, PictureAsPdf, AddAPhoto } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUrlBlobContainerImage } from "../../resources/imageConstants";

interface CampaignAddEditAdminProps {}

const CampaignAddEditAdmin = (props: CampaignAddEditAdminProps) => {
  const { state } = useLocation();
  const campaign = state?.campaign;
  const campaignClient = new CampaignClient();
  const groupClient = new GroupClient();
  const [loading, setLoading] = useState<boolean>(true);

  function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const [submitPressed, setSubmitPressed] = useState<boolean>(false);
  const [sdg, setSdg] = useState<any[]>();
  const [sdgValue, setSdgValue] = useState<any[]>();

  const [approvedBy, setApprovedBy] = useState<any[]>();
  const [approvedByValue, setApprovedByValue] = useState<any[]>();

  const [theme, setTheme] = useState<any[]>();
  const [themeValue, setThemeValue] = useState<any[]>();

  const [investmentType, setInvestmentType] = useState<any[]>();
  const [investmentTypeValue, setInvestmentTypeValue] = useState<any[]>();

  const [groups, setGroups] = useState<any[]>();

  const [tileImage, setTileImage] = useState<any>(
    campaign?.tileImage
      ? dataURLtoFile(campaign?.tileImage, "tileImage")
      : undefined
  );
  const [image, setImage] = useState<any>(
    campaign?.image ? dataURLtoFile(campaign?.image, "image") : undefined
  );
  const [logo, setLogo] = useState<any>(
    campaign?.logo ? dataURLtoFile(campaign?.logo, "logo") : undefined
  );
  const [pdf, setPdf] = useState<any>(
    campaign?.pdfPresentation
      ? dataURLtoFile(campaign?.pdfPresentation, "pdf")
      : undefined
  );
  const [originalPdfFileName, setPdfName] = useState<string>();

  const [notificationShow, setNotificationShow] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>("");

  const [stageValue, setStageValue] = useState<number | undefined>();

  const handleStageChange = (event: SelectChangeEvent<number | string>) => {
    const value = event.target.value as number;
    setStageValue(value);
  };

  useEffect(() => {
    setLoading(true);
    if (campaign) {
      Promise.all([
        campaignClient.campaign_GetData(),
        campaignClient.campaign_GetAdminCampaign(campaign.id),
        groupClient.group_GetAllGroups(),
      ])
        .then((res) => {
          setApprovedBy(res[0].approvedBy);
          setSdg(res[0].sdg);
          setTheme(res[0].theme);
          setInvestmentType(res[0].investmentType);
          setGroups(res[2]);
          setStageValue(res[1]?.stage);
          setPdfName(res[1].originalPdfFileName);
          console.log("response: ", res);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      Promise.all([
        campaignClient.campaign_GetData(),
        groupClient.group_GetAllGroups(),
      ])
        .then(async (response) => {
          const result = await response[0];
          setSdg(result.sdg);
          setTheme(result.theme);
          setInvestmentType(result.investmentType);
          setGroups(response[1]);
          setApprovedBy(result.approvedBy);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
    setApprovedByValue(campaign?.approvedBy?.split(",").map(Number) || []);
    setSdgValue(campaign?.sdGs.split(",").map(Number) || []);
    setThemeValue(campaign?.themes.split(",").map(Number) || []);
    setInvestmentTypeValue(
      campaign?.investmentTypes.split(",").map(Number) || []
    );
  }, []);

  const form = useForm<any>({
    defaultValues: {
      name: campaign?.name || "",
      description: campaign?.description || "",
      website: campaign?.website || "",
      terms: campaign?.terms || "",
      min: campaign?.minimumInvestment || "",
      contactFullName: campaign?.contactInfoFullName || "",
      contactAddress: campaign?.contactInfoAddress || "",
      contactEmail: campaign?.contactInfoEmailAddress || "",
      contactPhone: campaign?.contactInfoPhoneNumber || "",
      target: campaign?.target || "",
      sdg: campaign?.sdGs || [],
      theme: campaign?.themes || [],
      investmentType: campaign?.investmentTypes || [],
      stage: campaign?.stage || undefined,
      property: campaign?.property || "",
      addedTotalAdminRaised: campaign?.addedTotalAdminRaised || "",
      privateGroupID: campaign?.groupForPrivateAccessDto?.id || "",
    },
  });

  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const uploadTileImageChange = async (e: any) => {
    setTileImage(await toBase64(e.target.files[0]));
  };

  const uploadImageChange = async (e: any) => {
    if (e.target.files[0]) {
      setImage(await toBase64(e.target.files[0]));
    }
  };

  const uploadPdfChange = async (e: any) => {
    const file = e.target.files[0];
    const pdf = await toBase64(file);
    const originalPdfFileName = file.name;

    setPdf(pdf);
    setPdfName(originalPdfFileName);
  };

  const uploadLogoChange = async (e: any) => {
    if (e.target.files[0]) {
      setLogo(await toBase64(e.target.files[0]));
    }
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const onSubmit = async (data: any) => {
    if (!submitPressed) setSubmitPressed(true);

    if (campaign || (tileImage && image && logo && pdf)) {
      try {
        setLoading(true);
        let requestCampaign: Campaign = {
          id: campaign?.id || null,
          isActive: campaign?.isActive,
          contactInfoAddress: data.contactAddress,
          contactInfoEmailAddress: data.contactEmail,
          contactInfoFullName: data.contactFullName,
          contactInfoPhoneNumber: data.contactPhone,
          description: data.description,
          target: data.target,
          investmentTypes: data.investmentType.toString(),
          name: data.name,
          minimumInvestment: data.min,
          approvedBy: data.approvedBy.toString(),
          themes: data.theme.toString(),
          website: data.website,
          terms: data.terms,
          sdGs: data.sdg.toString(),
          stage: data?.stage ? Number(data.stage) : 0,
          property: data?.property,
          addedTotalAdminRaised: data?.addedTotalAdminRaised || 0,
          tileImageFileName: campaign?.tileImageFileName ?? "",
          imageFileName: campaign?.imageFileName ?? "",
          pdfFileName: campaign?.pdfFileName ?? "",
          logoFileName: campaign?.logoFileName ?? "",
          groupForPrivateAccessDto: groups?.find(
            (item) => Number(item.id) === Number(data.privateGroupID)
          ),
        };

        requestCampaign.tileImage =
          typeof tileImage === "string" ? tileImage : "";
        requestCampaign.image = typeof image === "string" ? image : "";
        requestCampaign.pdfPresentation = typeof pdf === "string" ? pdf : "";
        requestCampaign.logo = typeof logo === "string" ? logo : "";
        requestCampaign.originalPdfFileName = originalPdfFileName;

        if (campaign) {
          campaignClient
            .campaign_PutCampaign(requestCampaign, campaign.id)
            .then(async () => {
              setLoading(false);
              setNotificationText(`The Investment was successfully updated!`);
              setNotificationShow(true);
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        } else {
          campaignClient
            .campaign_PostCampaign(requestCampaign)
            .then(async (response) => {
              setLoading(false);
              setNotificationText(
                `The Investment '${response.name}' was successfully created!`
              );
              setNotificationShow(true);
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleApprovedByMultiselectChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setApprovedByValue(value);
  };

  const handleSdgMultiselectChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setSdgValue(value);
  };

  const handleThemeMultiselectChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setThemeValue(value);
  };

  const handleInvestmentTypeMultiselectChange = (
    event: SelectChangeEvent<any>
  ) => {
    const {
      target: { value },
    } = event;
    setInvestmentTypeValue(value);
  };

  const handleNotificationClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationShow(false);
  };

  return (
    <>
      {loading ? (
        <Box sx={{ margin: "0 auto" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto",
            mt: "50px",
            mb: "50px",
          }}
        >
          <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ ml: "2rem", mr: "2rem" }}
          >
            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Name
              </Typography>
              <TextField
                type="text"
                error={!!errors.name}
                helperText={errors.name?.message?.toString()}
                {...register("name", { required: "Name is required" })}
              ></TextField>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Description
              </Typography>
              <TextField
                multiline
                rows={5}
                type="text"
                error={!!errors.description}
                helperText={errors.description?.message?.toString()}
                {...register("description", {
                  required: "Description is required",
                })}
              ></TextField>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Website
              </Typography>
              <TextField
                type="text"
                error={!!errors.website}
                helperText={errors.website?.message?.toString()}
                {...register("website", { required: "Website is required" })}
              ></TextField>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Terms
              </Typography>
              <TextField
                multiline
                rows={6}
                type="text"
                error={!!errors.terms}
                helperText={errors.terms?.message?.toString()}
                {...register("terms", { required: "Terms is required" })}
              ></TextField>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Minumum Investment
              </Typography>
              <TextField
                type="number"
                error={!!errors.min}
                helperText={errors.min?.message?.toString()}
                {...register("min", {
                  required: "Minumum Investment is required",
                })}
              ></TextField>
            </FormControl>

            {approvedBy !== undefined && (
              <FormControl sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  Sourced By
                </Typography>
                <Select
                  multiple
                  value={approvedByValue}
                  error={!!errors.approvedBy}
                  {...register("approvedBy")}
                  onChange={handleApprovedByMultiselectChange}
                >
                  {approvedBy?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.approvedBy && "Sourced By is required"}
              </FormControl>
            )}

            {theme !== undefined && (
              <FormControl sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  Theme
                </Typography>
                <Select
                  multiple
                  value={themeValue}
                  error={!!errors.theme}
                  {...register("theme", { required: "Theme is required" })}
                  onChange={handleThemeMultiselectChange}
                >
                  {theme?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.investmentType && "Theme is required"}
              </FormControl>
            )}

            {sdg !== undefined && (
              <FormControl sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  SDG
                </Typography>
                <Select
                  multiple
                  value={sdgValue}
                  error={!!errors.sdg}
                  {...register("sdg", { required: "SDG is required" })}
                  onChange={handleSdgMultiselectChange}
                >
                  {sdg?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.investmentType && "SDG is required"}
              </FormControl>
            )}

            {investmentType !== undefined && (
              <FormControl sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  Investment Type
                </Typography>
                <Select
                  multiple
                  value={investmentTypeValue}
                  error={!!errors.investmentType}
                  {...register("investmentType", {
                    required: "Investment Type is required",
                  })}
                  onChange={handleInvestmentTypeMultiselectChange}
                >
                  {investmentType?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.investmentType && "Investment Type is required"}
              </FormControl>
            )}

            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Investment Stage
              </Typography>
              <Select
                value={stageValue}
                error={!!errors.stage}
                {...register("stage", {
                  required: "Investment Stage is required",
                })}
                onChange={handleStageChange}
              >
                <MenuItem value={InvestmentStage.Hidden}>Hidden</MenuItem>
                <MenuItem value={InvestmentStage.Private}>Private</MenuItem>
                <MenuItem value={InvestmentStage.Public}>Public</MenuItem>
              </Select>
              {errors.stage && "Investment Stage is required"}
            </FormControl>

            {groups !== undefined && groups.length >= 1 ? (
              <FormControl sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  Private Groups
                </Typography>
                <Select
                  error={!!errors.groups}
                  readOnly={campaign !== undefined ? true : false}
                  {...register("privateGroupID")}
                  value={form.watch("privateGroupID")}
                >
                  {groups?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <></>
            )}

            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Investment Name for URL - no spaces allowed
              </Typography>
              <TextField
                {...register("property", {
                  validate: (value) =>
                    /^[a-zA-Z0-9]+$/.test(value)
                      ? true
                      : "Investment Name for URL should be a string of letters and numbers only, without spaces or special characters",
                  required: "Investment Name for URL is required",
                })}
                error={!!errors.property}
                helperText={errors.property?.message?.toString()}
              />
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Total raised to date
              </Typography>
              <TextField
                type="number"
                {...register("addedTotalAdminRaised")}
                error={!!errors.addedTotalAdminRaised}
                helperText={errors.addedTotalAdminRaised?.message?.toString()}
              />
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Contact Full Name
              </Typography>
              <TextField
                type="text"
                error={!!errors.contactFullName}
                helperText={errors.contactFullName?.message?.toString()}
                {...register("contactFullName", {
                  required: "Contact Full Name is required",
                })}
              ></TextField>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Contact Address
              </Typography>
              <TextField
                type="text"
                error={!!errors.contactAddress}
                helperText={errors.contactAddress?.message?.toString()}
                {...register("contactAddress", {
                  required: "Contact Address is required",
                })}
              ></TextField>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Contact Email
              </Typography>
              <TextField
                type="text"
                error={!!errors.contactEmail}
                helperText={errors.contactEmail?.message?.toString()}
                {...register("contactEmail", {
                  required: "Contact Email is required",
                })}
              ></TextField>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Contact Phone
              </Typography>
              <TextField
                type="text"
                error={!!errors.contactPhone}
                helperText={errors.contactPhone?.message?.toString()}
                {...register("contactPhone", {
                  required: "Contact Phone is required",
                })}
              ></TextField>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#474747",
                }}
                align="left"
              >
                Target
              </Typography>
              <TextField
                type="number"
                error={!!errors.target}
                helperText={errors.target?.message?.toString()}
                {...register("target", { required: "Target is required" })}
              ></TextField>
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              {submitPressed && !tileImage && !campaign && (
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "red",
                  }}
                  align="left"
                >
                  Tile Image file is required
                </Typography>
              )}
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  Tile Image
                </Typography>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="center"
                >
                  <Avatar
                    src={
                      tileImage
                        ? tileImage
                        : getUrlBlobContainerImage(campaign?.tileImageFileName!)
                    }
                    sx={{ width: 100, height: 100 }}
                    variant="rounded"
                  />
                </Typography>
                <input
                  hidden
                  onChange={uploadTileImageChange}
                  accept=".png, .gif, .jpg"
                  type="file"
                />
                <PhotoCamera />
              </IconButton>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              {submitPressed && !image && !campaign && (
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "red",
                  }}
                  align="left"
                >
                  Image file is required
                </Typography>
              )}
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  Image
                </Typography>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="center"
                >
                  <Avatar
                    src={
                      image
                        ? image
                        : getUrlBlobContainerImage(campaign?.imageFileName!)
                    }
                    sx={{ width: 100, height: 100 }}
                    variant="rounded"
                  />
                </Typography>
                <input
                  hidden
                  onChange={uploadImageChange}
                  accept=".png, .gif, .jpg"
                  type="file"
                />
                <PhotoCamera />
              </IconButton>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              {submitPressed && !pdf && !campaign && (
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "red",
                  }}
                  align="left"
                >
                  PDF file is required
                </Typography>
              )}
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  PDF
                </Typography>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  {originalPdfFileName
                    ? originalPdfFileName
                    : "Please use this uploader if you want to update the current PDF file."}
                </Typography>
                <input
                  hidden
                  onChange={uploadPdfChange}
                  accept=".pdf"
                  type="file"
                />
                <PictureAsPdf />
              </IconButton>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              {submitPressed && !logo && !campaign && (
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "red",
                  }}
                  align="left"
                >
                  Logo file is required
                </Typography>
              )}
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="left"
                >
                  Logo
                </Typography>
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Ubuntu",
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#474747",
                  }}
                  align="center"
                >
                  <Avatar
                    src={
                      logo
                        ? logo
                        : getUrlBlobContainerImage(campaign?.logoFileName!)
                    }
                    sx={{ width: 100, height: 100 }}
                    variant="rounded"
                  />
                </Typography>
                <input
                  hidden
                  onChange={uploadLogoChange}
                  accept=".png, .gif, .jpg"
                  type="file"
                />
                <AddAPhoto />
              </IconButton>
            </FormControl>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              type="submit"
              color="primary"
            >
              {campaign ? "Update" : "Create"}
            </Button>
          </Stack>
        </Box>
      )}

      <Snackbar
        open={notificationShow && notificationText.length !== 0}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert
          onClose={handleNotificationClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {notificationText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CampaignAddEditAdmin;
